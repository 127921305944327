::-webkit-scrollbar {
    width: 8px;
    background-color: #292929;
}

::-webkit-scrollbar-thumb {
    background-color: #424242;
}

img {
    padding: 5rem;
    pointer-events: none;
}

.description {
    padding-left: 5rem;
    padding-right: 5rem;
}

.illustration {
    background-image: url("../assets/illustrationen.jpg");
    background-repeat: no-repeat, repeat;
    background-size: cover;
}

.mention {
    float: right;
    padding: 1rem;
    text-transform: capitalize;
    text-shadow: #0a0a0a 0px 0px 10px;
    color: white;
}

.mention a {
    color: white;
}
